<template>
  <div class="dashboard">
    <div class="row">
      <div class="flex xs12 md12">
        <va-card class="flex mb-4">
          <va-card-content style="padding: 0;" v-if="openedMarkerID">
            <div class="row">
              <div class="flex mb-2 md10" style="padding-top: .5rem;">
                <va-button preset="plain" class="my-0 live-text" style="background-color: transparent; margin-left: -1.5rem;" @click="toggleLive">
                  <div class="spinner-grow spinner-grow-sm" style="color: red;" v-if="liveOn"></div>
                  <div class="spinner-static spinner-static-sm" style="color: red;" v-else></div>&nbsp;Live
                </va-button>
                <va-icon size="1.5rem" name="material-icons-group" class="link" @click="toggleGroupMode" v-if="groupMode" />
                <va-icon size="1.5rem" name="material-icons-person" class="link" @click="toggleGroupMode" v-else />
                <va-icon size="1.5rem" name="loop" class="link" style="padding-left: 1rem;" @click="refreshSessions" />
              </div>
              <div class="flex mb-2 md2" v-if="openedMarkerIDs.length > 0">
                <va-button @click="showHeading" class="my-0">Show Heading</va-button>
                <va-button @click="showPath" class="my-0" style="margin-left: 0.5rem;">Show Path</va-button>
              </div>
              <div class="flex mb-2 md1 offset--md1" v-else>
                <va-button @click="focusMarker" class="my-0">Focus</va-button>
              </div>
            </div>
            <div class="row" style="margin-top: -1.5rem; margin-bottom: -1rem;" v-if="liveOn">
              <div class="flex mb-2 md2">
                <va-slider style="margin-left: 0.75rem; width: 100%;" v-model="liveTimeInterval" track-label-visible :step="5" :max="180" label="Minutes" invert-label @change="liveTimeIntervalChange" />
              </div>
            </div>
          </va-card-content>
          <va-card-content style="padding: 0;" v-else>
            <div class="row">
              <div class="flex mb-2 md9" style="padding-top: .5rem;" v-if="openedMarkerIDs.length > 0">
                <va-button preset="plain" class="my-0 live-text" style="background-color: transparent; margin-left: -1.5rem;" @click="toggleLive">
                  <div class="spinner-grow spinner-grow-sm" style="color: red;" v-if="liveOn"></div>
                  <div class="spinner-static spinner-static-sm" style="color: red;" v-else></div>&nbsp;Live
                </va-button>
                <va-icon size="1.5rem" name="material-icons-group" class="link" @click="toggleGroupMode" v-if="groupMode" />
                <va-icon size="1.5rem" name="material-icons-person" class="link" @click="toggleGroupMode" v-else />
                <va-icon size="1.5rem" name="loop" class="link" style="padding-left: 1rem;" @click="refreshSessions" />
              </div>
              <div class="flex mb-2 md2" style="padding-top: .5rem;" v-else>
                <va-button preset="plain" class="my-0 live-text" style="background-color: transparent; margin-left: -1.5rem;" @click="toggleLive">
                  <div class="spinner-grow spinner-grow-sm" style="color: red;" v-if="liveOn"></div>
                  <div class="spinner-static spinner-static-sm" style="color: red;" v-else></div>&nbsp;Live
                </va-button>
                <va-icon size="1.5rem" name="material-icons-group" class="link" @click="toggleGroupMode" v-if="groupMode" />
                <va-icon size="1.5rem" name="material-icons-person" class="link" @click="toggleGroupMode" v-else />
                <va-icon size="1.5rem" name="loop" class="link" style="padding-left: 1rem;" @click="refreshSessions" />
                <va-icon size="1.25rem" name="fas fa-filter" class="link" style="padding-left: 1rem;" @click="toggleFilters" v-if="isMobile" />
              </div>
              <div class="flex mb-2 md3" v-if="openedMarkerIDs.length > 0">
                <va-button @click="showHeading" class="my-0">Show Heading</va-button>
                <va-button @click="showPath" class="my-0" style="margin-left: 0.5rem;">Show Path</va-button>
              </div>
              <va-select
                class="flex mb-2 md2 xs12"
                :options="filterTypes"
                v-model="filterType"
                label="Filter Types"
                placeholder="Select Type"
                :clearable="false"
                v-if="openedMarkerIDs.length <= 0 && showFilters"
              />
              <va-select
                class="flex mb-2 md2 xs12"
                :options="filterSubTypes"
                v-model="filterSubType"
                label="Functional Types"
                placeholder="Select Type"
                :clearable="false"
                v-if="openedMarkerIDs.length <= 0 && filterType === 'Functional' && showFilters"
              />
              <div class="flex mb-2 md3 xs12" v-if="openedMarkerIDs.length <= 0 && filterType === 'Temporal' && showFilters">
                <div class="row">
                  <va-date-input
                    class="flex mb-2 md8 xs8"
                    v-model="filterDateStart"
                    label="Start Date"
                  />
                  <va-time-input
                    class="flex mb-2 md4 xs4"
                    v-model="filterTimeStart"
                  />
                </div>
                <div class="row" style="margin-top: -1.5rem; margin-bottom: -1.5rem;">
                  <va-date-input
                    class="flex mb-2 md8 xs8"
                    v-model="filterDateEnd"
                    label="End Date"
                  />
                  <va-time-input
                    class="flex mb-2 md4 xs4"
                    v-model="filterTimeEnd"
                  />
                </div>
              </div>
              <va-select
                class="flex mb-2 md2 xs12"
                :options="usersSelect"
                v-model="filterText"
                label="Users"
                placeholder="Select User"
                :clearable="false"
                multiple
                v-if="openedMarkerIDs.length <= 0 && filterType === 'Functional' && filterSubType !== 'Data Mode' && showFilters"
              />
              <va-select
                class="flex mb-2 md2 xs12"
                :options="modes"
                v-model="filterText"
                label="Data Modes"
                placeholder="Select Mode"
                :clearable="false"
                v-if="openedMarkerIDs.length <= 0 && filterType === 'Functional' && filterSubType === 'Data Mode' && showFilters"
              />
              <va-input
                class="flex mb-2 md2 xs12"
                placeholder="Filter..."
                v-model="filterText"
                v-on:keyup.enter="addFilter"
                v-else-if="openedMarkerIDs.length <= 0 && (filterType === 'All' || filterType === 'Name' || filterType === 'Spatial' || !filterType) && showFilters"
              />
              <div class="flex mb-2 md1 xs12" :class="isMobile ? 'text-center' : 'text-left'" :style="!isMobile ? 'padding-right: 4rem;' : ''" v-if="openedMarkerIDs.length <= 0 && showFilters">
                <va-button size="small" class="link" @click="addFilter" v-if="((filterType !== 'Temporal' && filterText.length > 0) || (filterType === 'Temporal' && filterDateStart)) && isMobile">Add</va-button>
                <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilter" v-else-if="(filterType !== 'Temporal' && filterText.length > 0) || (filterType === 'Temporal' && filterDateStart)" />
              </div>
              <div class="flex mb-2 md3 xs12" v-if="openedMarkerIDs.length <= 0 && filterType === 'Functional' && showFilters">
                <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
                  {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
                </va-chip>
              </div>
              <div class="flex mb-2 md4 xs12" v-else-if="openedMarkerIDs.length <= 0 && filterType === 'Temporal' && showFilters">
                <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
                  {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
                </va-chip>
              </div>
              <div class="flex mb-2 md5 xs12" v-else-if="openedMarkerIDs.length <= 0 && (filterType === 'All' || filterType === 'Name' || filterType === 'Spatial') && showFilters">
                <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
                  {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
                </va-chip>
              </div>
              <div class="flex mb-2 md5 xs12" v-else-if="openedMarkerIDs.length <= 0 && showFilters">
                <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
                  {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
                </va-chip>
              </div>
            </div>
            <div class="row" style="margin-top: -1.5rem; margin-bottom: -1rem;" v-if="liveOn">
              <div class="flex mb-2 md2 xs12">
                <va-slider style="margin-left: 0.75rem; width: 100%;" v-model="liveTimeInterval" track-label-visible :step="5" :max="180" label="Minutes" invert-label @change="liveTimeIntervalChange" />
              </div>
            </div>
          </va-card-content>
        </va-card>
        <va-card class="flex xs12 md12" style="margin-bottom: 1.5rem; height: 90%;">
          <GMapMap style="width: 100%; height: 75vh;" :center="center" :zoom="zoom" :options="options" ref="gMap" @bounds_changed="boundChanged" @zoom_changed="zoomChanged">
            <GMapMarker v-for="stat in markers"
              :key="stat.uid"
              :icon="stat.icon"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              :options="{ title: stat.user }"
              @click="openMarker(stat.uid, stat.lat, stat.lon)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null, null, null)"
                :opened="openedMarkerID === stat.uid"
              >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <div id="bodyContent">
                    <p style="font-weight: bold;">
                      {{ stat.user }}
                    </p>
                    <p class="detailFile" v-if="openedMarkerURL">
                      <router-link :to="{ name: 'files', params: { id: stat.session_id }}" target="_blank">
                        <img style="width: 4rem; cursor: pointer; margin-top: 1rem;" :src="openedMarkerURL.thumbnail" v-if="openedMarkerType !== 'Telemetry Mode'" />
                        <apexchart type="line" height="250" :options="chartOptions" :series="series" v-else-if="openedMarkerType !== 'LIDAR Mode'"></apexchart>
                      </router-link>
                    </p>
                    <p class="detailLink" v-else>
                      <router-link :to="{ name: 'files', params: { id: stat.session_id }}" target="_blank">
                        Details
                      </router-link>
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :icon="stat.icon"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              :options="{ title: stat.user }"
              @click="openMarker(stat.uid, stat.lat, stat.lon)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null, null, null)"
                :opened="openedMarkerID === stat.uid"
              >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <div id="bodyContent">
                    <p style="font-weight: bold;">
                      {{ stat.user }}
                    </p>
                    <p class="detailLink">
                      <router-link :to="{ name: 'files', params: { id: stat.session_id }}" target="_blank" v-if="openedMarkerURL">
                        <img style="width: 4rem; cursor: pointer; margin-top: 1rem;" :src="openedMarkerURL.thumbnail" v-if="openedMarkerType !== 'Telemetry Mode'" />
                        <apexchart type="line" height="250" :options="chartOptions" :series="series" v-else-if="openedMarkerType !== 'LIDAR Mode'"></apexchart>
                      </router-link>
                      <router-link :to="{ name: 'files', params: { id: stat.session_id }}" target="_blank" v-else>
                        Details
                      </router-link>
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapMarker
              v-for="lastPoint in lastPoints"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-for="directionPath in directionPaths" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-for="directionPathCourse in directionPathCourses" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" v-for="flightPath in flightPaths" />
          </GMapMap>
        </va-card>
      </div>
      <div class="flex xs12 md12" v-if="liveOn || filters.length > 0">
        <va-card class="flex xs12 md12">
          <va-card-content>
            <va-data-table
              :items="displayData"
              :columns="columns"
              :per-page="perPage"
              :current-page="currentPage"
              :selectable="true"
              @selection-change="selectedMarkerIDChange"
              v-model="selectedMarkerIDs"
              v-if="groupMode"
            >
              <template #bodyAppend>
                <tr>
                  <td colspan="4" class="table-example--pagination">
                    <va-pagination
                      v-model="currentPage"
                      input
                      :pages="pages"
                    />
                  </td>
                  <td colspan="3" class="table-example--pagination">
                    <va-select
                      style="width: 8rem;"
                      v-model="perPage"
                      :label="'Per Page'"
                      :options="perPageOptions"
                      noClear
                    />
                  </td>
                </tr>
              </template>
              <template #cell(uid)="{ source: uid }">
                <router-link :key="uid" :to="{ name: 'files', params: { id: uid }}">
                  <va-icon :name="'fa4-eye'"/>
                </router-link>
              </template>
            </va-data-table>
            <va-data-table
              :items="displayData"
              :columns="columns"
              :per-page="perPage"
              :current-page="currentPage"
              v-else
            >
              <template #bodyAppend>
                <tr>
                  <td colspan="4" class="table-example--pagination">
                    <va-pagination
                      v-model="currentPage"
                      input
                      :pages="pages"
                    />
                  </td>
                  <td colspan="3" class="table-example--pagination">
                    <va-select
                      style="width: 8rem;"
                      v-model="perPage"
                      :label="'Per Page'"
                      :options="perPageOptions"
                      noClear
                    />
                  </td>
                </tr>
              </template>
              <template #cell(uid)="{ source: uid }">
                <router-link :key="uid" :to="{ name: 'files', params: { id: uid }}">
                  <va-icon :name="'fa4-eye'"/>
                </router-link>
              </template>
            </va-data-table>
          </va-card-content>
        </va-card>
      </div>
      <!--
      <div class="flex xs12 md6">
        <router-link :to="{ name: 'users' }">
          <va-card class="grow">
            <va-card-content>
              <p class="display-2 mb-1" :style="{color: 'primary'}">{{ users }}</p>
              <p class="no-wrap">Users</p>
            </va-card-content>
          </va-card>
        </router-link>
      </div>
      <div class="flex xs12 md6">
        <router-link :to="{ name: 'sessions' }">
          <va-card class="grow">
            <va-card-content>
              <p class="display-2 mb-1" :style="{color: 'primary'}">{{ sessions }}</p>
              <p class="no-wrap">Sessions</p>
            </va-card-content>
          </va-card>
        </router-link>
      </div>
      -->
    </div>
    <!--
    <div class="row" v-if="sessionChartData && userChartData">
      <div class="flex md6 xs12">
        <va-card class="chart-widget">
          <va-card-title>
            <span>Uploaded Sessions by date</span>
            <span>Past 14 days</span>
          </va-card-title>
          <va-card-content>
            <va-chart :data="sessionChartData" type="vertical-bar"/>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex md6 xs12">
        <va-card class="chart-widget">
          <va-card-title>
            <span>Uploaded Sessions by Users</span>
            <span>Past 14 days</span>
          </va-card-title>
          <va-card-content>
            <va-chart :data="userChartData" type="vertical-bar"/>
          </va-card-content>
        </va-card>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import vuesticGlobalConfig from '@/services/vuestic-ui/global-config'
import { isMobile } from 'mobile-device-detect'
// import VaChart from '@/components/va-charts/VaChart'
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'dashboard',
  components: {
    // VaChart
    'apexchart': VueApexCharts
  },
  mounted: async function () {
    // Reset fields
    localStorage.setItem('currentPage', 1)
    localStorage.setItem('perPage', 10)

    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load Users
      const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`, { headers })
      this.users = users.data.users.length
      this.usersSelect = await Promise.all(users.data.users.map((user) => user.username))
      this.usersSelect.sort()

      // Load Sessions
      this.loadSessions()

      // Get current location
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude
      }, (err) => {
        console.log(error)
      })

      this.$refs.gMap.$mapPromise.then((map) => {
        this.google = window.google

        // Create the DIV to hold the control and call the CenterControl()
        // constructor passing in this DIV.
        let centerControlDiv = null
        if (this.isMobile) {
          centerControlDiv = document.createElement('div')
          centerControlDiv.innerHTML = `
          <div class="theme-colors-two" style="padding: 1rem .25rem; font-size: 8px; max-width: 6rem;">
            <div class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex legend-title-mobile">
                Legend
              </div>
            </div>
            <div id="heartbeatDiv" class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.greenMan}" style="height: 1rem;" />
                <br />Telemetry
              </div>
            </div>
            <div id="videoDiv" class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.redMan}" style="height: 1rem;" />
                <br />Video
              </div>
            </div>
            <div id="photoDiv" class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.whiteMan}" style="height: 1rem;" />
                <br />Photo
              </div>
            </div>
            <div id="threeDDiv" class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.blueMan}" style="height: 1rem;" />
                <br />LIDAR
              </div>
            </div>
            <div class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <hr class="theme-colors-one" style="border: none; border-bottom: 2px dashed #00ff00; height: 1px; width: 1rem; margin-top: .5rem;" />
                <br />Path
              </div>
            </div>
            <div class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <span style="color: #FA86C4; font-size: 1.5rem;">&rarr;</span>
                <br />Heading
              </div>
            </div>
            <div class="row" style="width: 4rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <span style="color: #006721; font-size: 1.5rem;">&rarr;</span>
                <br />Course
              </div>
            </div>
          </div>
          `
        } else {
          centerControlDiv = document.createElement('div')
          centerControlDiv.innerHTML = `
          <div class="theme-colors-two" style="padding: 1rem .25rem; font-size: 10px; max-width: 6rem;">
            <div class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex legend-title">
                Legend
              </div>
            </div>
            <div id="heartbeatDiv" class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.greenMan}" style="height: 2rem;" />
                <br />Telemetry
              </div>
            </div>
            <div id="videoDiv" class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.redMan}" style="height: 2rem;" />
                <br />Video
              </div>
            </div>
            <div id="photoDiv" class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.whiteMan}" style="height: 2rem;" />
                <br />Photo
              </div>
            </div>
            <div id="threeDDiv" class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <img src="${this.blueMan}" style="height: 2rem;" />
                <br />LIDAR
              </div>
            </div>
            <div class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <hr class="theme-colors-one" style="border: none; border-bottom: 2px dashed #00ff00; height: 1px; width: 1rem; margin-top: .5rem;" />
                <br />Path
              </div>
            </div>
            <div class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <span style="color: #FA86C4; font-size: 1.5rem;">&rarr;</span>
                <br />Heading
              </div>
            </div>
            <div class="row" style="width: 5rem; padding: .25rem 0;">
              <div class="flex" style="width: 100%; text-align: center; padding: 0;">
                <span style="color: #006721; font-size: 1.5rem;">&rarr;</span>
                <br />Course
              </div>
            </div>
          </div>
          `
        }
        map.controls[google.maps.ControlPosition.LEFT_CENTER].push(
          centerControlDiv
        )

        const mapControlDiv = document.createElement('div');
        mapControlDiv.innerHTML = `
        <div class="theme-colors-two" style="padding: 1rem .25rem; font-size: 1rem; max-width: 10rem;">
          <div class="row" style="width: 6rem; padding: .25rem 0;">
            <div class="flex" style="width: 100%; text-align: center; padding: 0;">
              <input checked type="checkbox" id="labelsCheck" style="top: 3px; width: 1rem; height: 1rem; vertical-align:middle;"><span>&nbsp;Labels<span>
            </div>
          </div>
        </div>
        `
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
          mapControlDiv
        )
      })

      setTimeout(() => {
        const labelsCheck = document.getElementById('labelsCheck')

        labelsCheck.addEventListener('change', (event) => {
          if (event.currentTarget.checked) {
            if (this.options.styles[this.options.styles.length - 1].elementType && this.options.styles[this.options.styles.length - 1].elementType === 'labels') {
              this.options.styles.pop()
            }
          } else {
            if (!this.options.styles[this.options.styles.length - 1].elementType || this.options.styles[this.options.styles.length - 1].elementType !== 'labels') {
              this.options.styles.push({
                "elementType": "labels",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              })
            }
          }
        })

        const heartbeatDiv = document.getElementById('heartbeatDiv')
        heartbeatDiv.addEventListener('click', (event) => {
          this.filtersTypes = []
          this.filters = []
          this.filterType = 'Functional'
          this.filterSubType = 'Data Mode'
          this.filterText = 'Telemetry Mode'
          this.showFilters = true
          this.addFilter()
        })

        const videoDiv = document.getElementById('videoDiv')
        videoDiv.addEventListener('click', (event) => {
          this.filtersTypes = []
          this.filters = []
          this.filterType = 'Functional'
          this.filterSubType = 'Data Mode'
          this.filterText = 'Video Mode'
          this.showFilters = true
          this.addFilter()
        })

        const photoDiv = document.getElementById('photoDiv')
        photoDiv.addEventListener('click', (event) => {
          this.filtersTypes = []
          this.filters = []
          this.filterType = 'Functional'
          this.filterSubType = 'Data Mode'
          this.filterText = 'Photo Mode'
          this.showFilters = true
          this.addFilter()
        })

        const threeDDiv = document.getElementById('threeDDiv')
        threeDDiv.addEventListener('click', (event) => {
          this.filtersTypes = []
          this.filters = []
          this.filterType = 'Functional'
          this.filterSubType = 'Data Mode'
          this.filterText = 'LIDAR Mode'
          this.showFilters = true
          this.addFilter()
        })
      }, 2000)
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
    }

    if (this.isMobile) {
      this.showFilters = false
      this.columns = [
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        },
        {
          key: 'label',
          label: 'Sessions',
          headerTitle: 'Sessions',
          sortable: true
        },
        {
          key: 'mode',
          label: 'Mode',
          headerTitle: 'mode',
          sortable: true
        },
        {
          key: 'address',
          label: 'Address',
          headerTitle: 'Address',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          headerTitle: 'User',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        }
      ]
    }
  },
  data () {
    return {
      isMobile,
      vuesticGlobalConfig,
      google: null,
      loader: null,
      token: this.$store.state.token,
      users: 0,
      sessions: 0,
      sessionsData: [],
      stats: [],
      sessionChartData: null,
      userChartData: null,
      markers: [],
      infoWindows: [],
      center: {
        lat: 40.801045,
        lng: -102.098659
      },
      zoom: 4,
      bounds: null,
      processViewChangeTimeout: null,
      visibleData: {},
      whiteMan: require('@/assets/google-icon/man-yellow.png'),
      greenMan: require('@/assets/google-icon/man-green.png'),
      redMan: require('@/assets/google-icon/man-red.png'),
      blueMan: require('@/assets/google-icon/man-blue.png'),
      owhiteMan: require('@/assets/google-icon/outlined-man-yellow.png'),
      ogreenMan: require('@/assets/google-icon/outlined-man-green.png'),
      oredMan: require('@/assets/google-icon/outlined-man-red.png'),
      oblueMan: require('@/assets/google-icon/outlined-man-blue.png'),
      openedMarkerID: null,
      openedMarkerLat: null,
      openedMarkerLon: null,
      openedMarkerURL: null,
      openedMarkerType: null,
      openedMarkerIDs: [],
      selectedMarkerIDs: [],
      groupMode: false,
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8ec3b9"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1a3646"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#64779e"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#334e87"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6f9ba5"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3C7680"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#304a7d"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2c6675"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#255763"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#b0d5ce"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3a4762"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#0e1626"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#4e6d70"
              }
            ]
          }
        ]
      },
      filterText: '',
      filterDateStart: null,
      filterDateEnd: null,
      filterTimeStart: null,
      filterTimeEnd: null,
      filters: localStorage.getItem('filters') ? localStorage.getItem('filters').split(',') : [],
      filtersTypes: localStorage.getItem('filtersTypes') ? localStorage.getItem('filtersTypes').split(',') : [],
      filterTypes: ['All', 'Name', 'Spatial', 'Temporal', 'Functional'],
      filterSubTypes: ['Data Mode', 'User'],
      filterType: null,
      filterSubType: null,
      usersSelect: [],
      modes: ['Telemetry Mode', 'Video Mode', 'Photo Mode', 'LIDAR Mode'],
      liveOn: false,
      liveInterval: null,
      liveTimeInterval: 5,
      displayData: [],
      directionPaths: [],
      directionPathCourses: [],
      flightPaths: [],
      lastPoints: [],
      columns: [
        {
          key: 'label',
          label: 'Sessions',
          headerTitle: 'Sessions',
          sortable: true
        },
        {
          key: 'mode',
          label: 'Mode',
          headerTitle: 'mode',
          sortable: true
        },
        {
          key: 'address',
          label: 'Address',
          headerTitle: 'Address',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          headerTitle: 'User',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      perPage: localStorage.getItem('perPage') ? parseInt(localStorage.getItem('perPage')) : 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: localStorage.getItem('currentPage') ? parseInt(localStorage.getItem('currentPage')) : 1,
      showFilters: true,
      series: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime'
        },
        colors: ['#0000FF', '#5F0F40', '#00B577', '#FF0025', '#4F399A', '#008DFB', '#006E48', '#888f01']
      }
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.displayData.length / this.perPage)
        : this.displayData.length
    }
  },
  methods: {
    loadSessions: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const filter_types = []
        const filter_values = []
        // Prepare filters
        this.filtersTypes.forEach((type, i) => {
          switch (type) {
            case 'All':
              filter_types.push('all')
              filter_values.push(this.filters[i])
              break
            case 'Name':
              filter_types.push('label')
              filter_values.push(this.filters[i])
              break
            case 'Spatial':
              filter_types.push('address')
              filter_values.push(this.filters[i])
              break
            case 'Temporal':
              filter_types.push('created_at')
              if (this.filters[i].split('to').length <= 1) {
                const start_time = moment(`${this.filters[i]} 00:00`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(`${this.filters[i]} 23:59`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              } else {
                const start_time = moment(this.filters[i].split(' to ')[0], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(this.filters[i].split(' to ')[1], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              }
              break
            case 'Functional - User':
              filter_types.push('user')
              filter_values.push(this.filters[i])
              break
            case 'Functional - Data Mode':
              filter_types.push('mode')
              filter_values.push(this.filters[i])
              break
            default:
              break
          }
        })

        let url = `${process.env.VUE_APP_API_URL}/api/sessions?page_no=1&per_page=2500`
        if (filter_types.length > 0) {
          url = `${process.env.VUE_APP_API_URL}/api/sessions?page_no=1&per_page=10000&filter_types=${filter_types.toString()}&filter_values=${filter_values.toString()}`
        }
        // Load a list of sessions
        const sessions = await axios.get(url, { headers })
        this.sessions = await Promise.all(sessions.data.sessions.map(async (session) => {
          session.created_at = moment.utc(session.created_at).toDate()
          session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
          return session
        }))
        this.filtered = this.sessions
        this.displayData = this.sessions
        this.processSessions(false)
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
      }
      this.loader.hide()
    },
    processSessions: async function (loadingFlag = true) {
      if (loadingFlag) {
        this.loader = this.$loading.show()
      }
      try {
        this.infoWindows = []
        this.markers = []
        const sessions = this.filtered
        await Promise.all(sessions.map(async (session) => {
          if (session) {
            if (session.mode === 'Telemetry Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.greenMan,
                  type: session.mode
                })
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.greenMan,
                  type: session.mode
                })
              }
            } else if (session.mode === 'Video Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.redMan,
                  type: session.mode
                });
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.redMan,
                  type: session.mode
                })
              }
            } else if (session.mode === 'Photo Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.whiteMan,
                  type: session.mode
                })
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.whiteMan,
                  type: session.mode
                })
              }
            } else if (session.mode === 'LIDAR Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.blueMan,
                  type: session.mode
                })
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.blueMan,
                  type: session.mode
                })
              }
            }
          }
        }))
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
      }
      this.loader.hide()
    },
    openMarker: async function (id, lat, lon) {
      if (this.groupMode) {
        if (id) {
          let isPresent = false
          this.openedMarkerIDs.forEach((markerID, i) => {
            if (markerID === id) {
              isPresent = true
              delete this.openedMarkerIDs[i]
            }
          })
          this.selectedMarkerIDs.forEach((selected, i) => {
            if (selected.uid === id) {
              delete this.selectedMarkerIDs[i]
            }
          })

          if (isPresent) {
            this.markers.forEach((marker) => {
              if (marker.uid === id) {
                if (marker.type === 'Telemetry Mode') {
                  marker.icon = this.greenMan
                } else if (marker.type === 'Video Mode') {
                  marker.icon = this.redMan
                } else if (marker.type === 'Photo Mode') {
                  marker.icon = this.whiteMan
                } else if (marker.type === 'LIDAR Mode') {
                  marker.icon = this.blueMan
                }
              }
            })
            this.infoWindows.forEach((infoWindow) => {
              if (infoWindow.uid === id) {
                if (infoWindow.type === 'Telemetry Mode') {
                  infoWindow.icon = this.greenMan
                } else if (infoWindow.type === 'Video Mode') {
                  infoWindow.icon = this.redMan
                } else if (infoWindow.type === 'Photo Mode') {
                  infoWindow.icon = this.whiteMan
                } else if (infoWindow.type === 'LIDAR Mode') {
                  infoWindow.icon = this.blueMan
                }
              }
            })
            this.openedMarkerIDs = _.compact(this.openedMarkerIDs)
            this.selectedMarkerIDs = _.compact(this.selectedMarkerIDs)
          } else {
            if (this.openedMarkerIDs.length >= 10) {
              this.$vaToast.init('Selection of max 10 allowed.')
            } else {
              this.markers.forEach((marker) => {
                if (marker.uid === id) {
                  if (marker.type === 'Telemetry Mode') {
                    marker.icon = this.ogreenMan
                  } else if (marker.type === 'Video Mode') {
                    marker.icon = this.oredMan
                  } else if (marker.type === 'Photo Mode') {
                    marker.icon = this.owhiteMan
                  } else if (marker.type === 'LIDAR Mode') {
                    marker.icon = this.oblueMan
                  }
                }
              })
              this.infoWindows.forEach((infoWindow) => {
                if (infoWindow.uid === id) {
                  if (infoWindow.type === 'Telemetry Mode') {
                    infoWindow.icon = this.ogreenMan
                  } else if (infoWindow.type === 'Video Mode') {
                    infoWindow.icon = this.oredMan
                  } else if (infoWindow.type === 'Photo Mode') {
                    infoWindow.icon = this.owhiteMan
                  } else if (infoWindow.type === 'LIDAR Mode') {
                    infoWindow.icon = this.oblueMan
                  }
                }
              })
              this.openedMarkerIDs.push(id)

              this.filtered.forEach((filteredData) => {
                if (filteredData.uid === id) {
                  this.selectedMarkerIDs.push(filteredData)
                }
              })
            }
          }
        }
      } else {
        let type = null
        if (id) {
          this.openedMarkerURL = null
          this.openedMarkerType = null
          this.markers.forEach((marker) => {
            if (marker.uid === id) {
              type = marker.type
              if (marker.type === 'Telemetry Mode') {
                marker.icon = this.ogreenMan
              } else if (marker.type === 'Video Mode') {
                marker.icon = this.oredMan
              } else if (marker.type === 'Photo Mode') {
                marker.icon = this.owhiteMan
              } else if (marker.type === 'LIDAR Mode') {
                marker.icon = this.oblueMan
              }
            }
          })
          this.infoWindows.forEach((infoWindow) => {
            if (infoWindow.uid === id) {
              type = infoWindow.type
              if (infoWindow.type === 'Telemetry Mode') {
                infoWindow.icon = this.ogreenMan
              } else if (infoWindow.type === 'Video Mode') {
                infoWindow.icon = this.oredMan
              } else if (infoWindow.type === 'Photo Mode') {
                infoWindow.icon = this.owhiteMan
              } else if (infoWindow.type === 'LIDAR Mode') {
                infoWindow.icon = this.oblueMan
              }
            }
          })
        } else {
          this.flightPaths = []
          this.lastPoints = []
          this.markers.forEach((marker) => {
            if (marker.type === 'Telemetry Mode') {
              marker.icon = this.greenMan
            } else if (marker.type === 'Video Mode') {
              marker.icon = this.redMan
            } else if (marker.type === 'Photo Mode') {
              marker.icon = this.whiteMan
            } else if (marker.type === 'LIDAR Mode') {
              marker.icon = this.blueMan
            }
          })
          this.infoWindows.forEach((infoWindow) => {
            if (infoWindow.type === 'Telemetry Mode') {
              infoWindow.icon = this.greenMan
            } else if (infoWindow.type === 'Video Mode') {
              infoWindow.icon = this.redMan
            } else if (infoWindow.type === 'Photo Mode') {
              infoWindow.icon = this.whiteMan
            } else if (infoWindow.type === 'LIDAR Mode') {
              infoWindow.icon = this.blueMan
            }
          })
        }
        this.openedMarkerID = id
        this.openedMarkerLat = lat
        this.openedMarkerLon = lon

        if (type === 'Photo Mode' || type === 'Video Mode') {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files`, { headers })
          if (files.data.files && files.data.files[0]) {
            const file = files.data.files[0]
            const fileLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files/${file.uid}/download`, { headers })
            this.openedMarkerURL = fileLink.data.file
            this.openedMarkerType = type
          }
        } else if (type === 'Telemetry Mode') {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const sessionResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files?sort_by=timestamp`, { headers })
          let file = sessionResponse.data.files[0]

          this.series[0].data = []
          this.series[1].data = []
          this.series[2].data = []
          this.series[3].data = []
          this.series[4].data = []
          this.series[5].data = []
          this.series[6].data = []
          this.series[7].data = []
          this.chartOptions.xaxis.categories = []

          const fileResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files/${sessionResponse.data.files[0].uid}`, { headers })
          const stats = fileResponse.data.file.stats

          stats.forEach((stat) => {
            // Fill graph data
            this.series[0].data.push(Math.round(Math.pow(10, stat.mic) * 10000) / 10000)
            this.series[1].data.push(stat.accelUserX)
            this.series[2].data.push(stat.accelUserY)
            this.series[3].data.push(stat.accelUserZ)
            this.series[4].data.push(stat.gyroX)
            this.series[5].data.push(stat.gyroY)
            this.series[6].data.push(stat.gyroZ)
            this.series[7].data.push(stat.speed)
            this.chartOptions.xaxis.categories.push(stat.timestamp)
          })

          this.openedMarkerURL = {
            thumbnail: 'Chart'
          }
          this.openedMarkerType = type

          if (file && file.stats && file.stats[0]) {
            const filesResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files-ids?sort_by=timestamp`, {}, { headers })
            const files = filesResponse.data.files
            let stats = []

            files.forEach((file) => {
              stats.push(Object.assign(file.first_location, { parent_id: file.uid }))
              stats.push(Object.assign(file.last_location, { parent_id: file.uid }))
            })
            stats = stats.sort((a,b) => {
              return new Date(a.timestamp) - new Date(b.timestamp)
            })

            const flightPlanCoordinates = []
            const flightPlanCoordinatesTracker = []
            stats.forEach((stat) => {
              const newCoordinate = {
                lat: parseFloat(stat.lat),
                lng: parseFloat(stat.lon)
              }
              const newCoordinateTracker = `${stat.lat},${stat.lon}`
              if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
                flightPlanCoordinates.push(newCoordinate)
                flightPlanCoordinatesTracker.push(newCoordinateTracker)
              }
            })

            const lineSymbol = {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 4,
            }
            this.flightPaths.push({
              path: flightPlanCoordinates,
              geodesic: true,
              strokeColor: '#00ff00',
              strokeOpacity: 0,
              strokeWeight: 2,
              icons: [
                {
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '20px',
                },
              ],
            })

            this.lastPoints.push({
              lat: parseFloat(stats[stats.length - 1].lat),
              lng: parseFloat(stats[stats.length - 1].lon)
            })
          }
        }
      }
    },
    toggleGroupMode () {
      if (this.groupMode) {
        this.openedMarkerIDs = []
        this.selectedMarkerIDs = []
        this.groupMode = false
      } else {
        this.openedMarkerID = null
        this.openedMarkerLat = null
        this.openedMarkerLon = null
        this.openedMarkerURL = null
        this.openedMarkerType = null
        this.groupMode = true
      }

      this.markers.forEach((marker) => {
        if (marker.type === 'Telemetry Mode') {
          marker.icon = this.greenMan
        } else if (marker.type === 'Video Mode') {
          marker.icon = this.redMan
        } else if (marker.type === 'Photo Mode') {
          marker.icon = this.whiteMan
        } else if (marker.type === 'LIDAR Mode') {
          marker.icon = this.blueMan
        }
      })
      this.infoWindows.forEach((infoWindow) => {
        if (infoWindow.type === 'Telemetry Mode') {
          infoWindow.icon = this.greenMan
        } else if (infoWindow.type === 'Video Mode') {
          infoWindow.icon = this.redMan
        } else if (infoWindow.type === 'Photo Mode') {
          infoWindow.icon = this.whiteMan
        } else if (infoWindow.type === 'LIDAR Mode') {
          infoWindow.icon = this.blueMan
        }
      })
    },
    addFilter: function () {
      switch (this.filterType) {
        case 'All':
        case 'Name':
        case 'Spatial':
          if (this.filterText && this.filterText.length > 0) {
            this.filters.push(this.filterText.trim())
            this.filtersTypes.push(this.filterType)
          }
          break
        case 'Temporal':
          if (this.filterDateStart) {
            const startDate = new Date(this.filterDateStart).toISOString().split('T')[0]
            const endDate = this.filterDateEnd ? new Date(this.filterDateEnd).toISOString().split('T')[0] : null
            const startTime = this.filterTimeStart ? `${this.filterTimeStart.toString().split(' ')[4].split(':')[0]}:${this.filterTimeStart.toString().split(' ')[4].split(':')[1]}` : '00:00'
            const endTime = this.filterTimeEnd && this.filterDateEnd ? `${this.filterTimeEnd.toString().split(' ')[4].split(':')[0]}:${this.filterTimeEnd.toString().split(' ')[4].split(':')[1]}` : '23:59'
            let tempDate = `${startDate} ${startTime}`
            if (endDate) {
              tempDate = `${tempDate} to ${endDate} ${endTime}`
            } else {
              tempDate = startDate
            }
            this.filters.push(tempDate)
            this.filtersTypes.push(this.filterType)
          }
          break
        case 'Functional':
          if (this.filterText && this.filterText.length > 0) {
            if (this.filterSubType === 'User') {
              this.filters.push(this.filterText.join(';').trim())
            } else {
              this.filters.push(this.filterText.trim())
            }
            this.filtersTypes.push(`${this.filterType} - ${this.filterSubType}`)
          }
          break
        default:
      }
      localStorage.setItem('filters', this.filters)
      localStorage.setItem('filtersTypes', this.filtersTypes)
      this.filterText = ''
      this.filterType = ''
      this.loadSessions()
    },
    removeFilter: function (index) {
      this.filters.splice(index, 1)
      this.filtersTypes.splice(index, 1)
      localStorage.setItem('filters', this.filters)
      localStorage.setItem('filtersTypes', this.filtersTypes)
      this.loadSessions()
    },
    focusMarker () {
      if (this.openedMarkerID) {
        this.center = {
          lat: parseFloat(this.openedMarkerLat),
          lng: parseFloat(this.openedMarkerLon)
        }
        this.zoom = 15
      }
    },
    liveTimeIntervalChange: async function () {
      try {
        this.directionPaths = []
        this.directionPathCourses = []
        this.flightPaths = []
        this.lastPoints = []
        this.infoWindows = []
        this.markers = []
        this.lastPoints = []

        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const now = moment()
        this.displayData = []
        // Load live sessions
        const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/live-sessions?interval=${this.liveTimeInterval}`, { headers })
        this.sessionsData = await Promise.all(sessions.data.sessions.map(async (session) => {
          session.created_at = moment.utc(session.created_at).toDate()
          session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
          return session
        }))
        this.filtered = this.sessionsData
        await Promise.all(this.filtered.map(async (session) => {
          if (session) {
            this.displayData.push(session)
            if (session.mode === 'Telemetry Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.greenMan,
                  type: session.mode
                });
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.greenMan,
                  type: session.mode
                });
              }
            } else if (session.mode === 'Video Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.redMan,
                  type: session.mode
                });
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.redMan,
                  type: session.mode
                });
              }
            } else if (session.mode === 'Photo Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.whiteMan,
                  type: session.mode
                });
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.whiteMan,
                  type: session.mode
                });
              }
            } else if (session.mode === 'LIDAR Mode') {
              if (session.address) {
                this.infoWindows.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  address: session.address,
                  session_id: session.uid,
                  icon: this.blueMan,
                  type: session.mode
                });
              } else if (session.lat) {
                this.markers.push({
                  uid: session.uid,
                  user: session.user,
                  lat: session.lat,
                  lon: session.lon,
                  session_id: session.uid,
                  icon: this.blueMan,
                  type: session.mode
                });
              }
            }
          }
        }))
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
      }
    },
    refreshSessions: async function () {
      this.loader = this.$loading.show()

      try {
        this.directionPaths = []
        this.directionPathCourses = []
        this.flightPaths = []
        this.lastPoints = []
        this.infoWindows = []
        this.markers = []
        this.lastPoints = []
        this.visibleData = {}

        const headers = {
          Authorization: `Bearer ${this.token}`
        }

        if (this.liveOn) {
          const now = moment()
          this.displayData = []
          // Load live sessions
          const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/live-sessions?interval=${this.liveTimeInterval}`, { headers })
          this.sessionsData = await Promise.all(sessions.data.sessions.map(async (session) => {
            session.created_at = moment.utc(session.created_at).toDate()
            session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
            return session
          }))
          this.filtered = this.sessionsData
          await Promise.all(this.filtered.map(async (session) => {
            if (session) {
              this.displayData.push(session)
              if (session.mode === 'Telemetry Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Video Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Photo Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'LIDAR Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                }
              }
            }
          }))
          this.processViewChange(this.zoom, this.bounds)
        } else {
          const filter_types = []
          const filter_values = []
          // Prepare filters
          this.filtersTypes.forEach((type, i) => {
            switch (type) {
              case 'All':
                filter_types.push('all')
                break
              case 'Name':
                filter_types.push('label')
                break
              case 'Spatial':
                filter_types.push('address')
                break
              case 'Temporal':
                filter_types.push('created_at')
                break
              case 'Functional - User':
                filter_types.push('user')
                break
              case 'Functional - Data Mode':
                filter_types.push('mode')
                break
              default:
                break
            }
            filter_values.push(this.filters[i])
          })

          let url = `${process.env.VUE_APP_API_URL}/api/sessions?page_no=1&per_page=2500`
          if (filter_types.length > 0) {
            url = `${process.env.VUE_APP_API_URL}/api/sessions?page_no=1&per_page=10000&filter_types=${filter_types.toString()}&filter_values=${filter_values.toString()}`
          }
          // Load a list of sessions
          const sessions = await axios.get(url, { headers })
          this.sessions = sessions.data.sessions.length
          this.sessionsData = await Promise.all(sessions.data.sessions.map(async (session) => {
            session.created_at = moment.utc(session.created_at).toDate()
            session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
            return session
          }))
          await Promise.all(this.filtered.map(async (session) => {
            if (session) {
              if (session.mode === 'Telemetry Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Video Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Photo Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'LIDAR Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                }
              }
            }
          }))
          this.processViewChange(this.zoom, this.bounds)
        }
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
      }
      this.loader.hide()
    },
    toggleLive: async function () {
      this.liveOn = !this.liveOn
      this.infoWindows = []
      this.markers = []
      this.directionPaths = []
      this.directionPathCourses = []
      this.flightPaths = []
      this.lastPoints = []
      this.filters = []
      this.filtersTypes = []
      this.visibleData = {}
      localStorage.setItem('filters', this.filters)
      localStorage.setItem('filtersTypes', this.filtersTypes)

      if (this.liveOn) {
        try {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const now = moment()
          this.displayData = []

          // Load Sessions
          const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/live-sessions?interval=${this.liveTimeInterval}`, { headers })
          this.sessionsData = await Promise.all(sessions.data.sessions.map(async (session) => {
              session.created_at = moment.utc(session.created_at).toDate()
              session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
              return session
          }))
          this.filtered = this.sessionsData
          await Promise.all(sessions.data.sessions.map(async (session) => {
            if (session) {
              this.displayData.push(session)
              if (session.mode === 'Telemetry Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Video Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Photo Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'LIDAR Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                }
              }
            }
          }))
          this.processViewChange(this.zoom, this.bounds)
        } catch (error) {
          this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
        }
        this.liveInterval = setInterval(async () => {
          try {
            const headers = {
              Authorization: `Bearer ${this.token}`
            }
            const now = moment()

            this.displayData = []
            this.infoWindows = []
            this.markers = []
            this.directionPaths = []
            this.directionPathCourses = []
            this.flightPaths = []
            this.lastPoints = []

            // Load Sessions
            const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/live-sessions?interval=${this.liveTimeInterval}`, { headers })
            this.sessionsData = await Promise.all(sessions.data.sessions.map(async (session) => {
              session.created_at = moment.utc(session.created_at).toDate()
              session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
              return session
            }))
            this.filtered = this.sessionsData
            await Promise.all(sessions.data.sessions.map(async (session) => {
              if (session) {
                this.displayData.push(session)
                if (session.mode === 'Telemetry Mode') {
                  if (session.address) {
                    this.infoWindows.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      address: session.address,
                      session_id: session.uid,
                      icon: this.greenMan,
                      type: session.mode
                    });
                  } else if (session.lat) {
                    this.markers.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      session_id: session.uid,
                      icon: this.greenMan,
                      type: session.mode
                    });
                  }
                } else if (session.mode === 'Video Mode') {
                  if (session.address) {
                    this.infoWindows.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      address: session.address,
                      session_id: session.uid,
                      icon: this.redMan,
                      type: session.mode
                    });
                  } else if (session.lat) {
                    this.markers.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      session_id: session.uid,
                      icon: this.redMan,
                      type: session.mode
                    });
                  }
                } else if (session.mode === 'Photo Mode') {
                  if (session.address) {
                    this.infoWindows.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      address: session.address,
                      session_id: session.uid,
                      icon: this.whiteMan,
                      type: session.mode
                    });
                  } else if (session.lat) {
                    this.markers.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      session_id: session.uid,
                      icon: this.whiteMan,
                      type: session.mode
                    });
                  }
                } else if (session.mode === 'LIDAR Mode') {
                  if (session.address) {
                    this.infoWindows.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      address: session.address,
                      session_id: session.uid,
                      icon: this.blueMan,
                      type: session.mode
                    });
                  } else if (session.lat) {
                    this.markers.push({
                      uid: session.uid,
                      user: session.user,
                      lat: session.lat,
                      lon: session.lon,
                      session_id: session.uid,
                      icon: this.blueMan,
                      type: session.mode
                    });
                  }
                }
              }
            }))
            this.processViewChange(this.zoom, this.bounds)
          } catch (error) {
            this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
          }
        }, 60000)
      } else {
        clearInterval(this.liveInterval)
        this.loader = this.$loading.show()
        try {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const now = moment()

          // Load Sessions
          const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions?page_no=1&per_page=2500`, { headers })
          this.sessions = sessions.data.sessions.length

          this.sessionsData = await Promise.all(sessions.data.sessions.map(async (session) => {
            session.created_at = moment.utc(session.created_at).toDate()
            session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
            return session
          }))
          await Promise.all(sessions.data.sessions.map(async (session) => {
            if (session) {
              if (session.mode === 'Telemetry Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.greenMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Video Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.redMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'Photo Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.whiteMan,
                    type: session.mode
                  });
                }
              } else if (session.mode === 'LIDAR Mode') {
                if (session.address) {
                  this.infoWindows.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    address: session.address,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                } else if (session.lat) {
                  this.markers.push({
                    uid: session.uid,
                    user: session.user,
                    lat: session.lat,
                    lon: session.lon,
                    session_id: session.uid,
                    icon: this.blueMan,
                    type: session.mode
                  });
                }
              }
            }
          }))
          this.processViewChange(this.zoom, this.bounds)
        } catch (error) {
          this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
        }
        this.loader.hide()
      }
    },
    showHeading: async function () {
      if (this.openedMarkerIDs.length > 0) {
        this.loader = this.$loading.show()
        try {
          this.infoWindows = []
          this.markers = []
          this.liveOn = false
          await Promise.all(this.openedMarkerIDs.map(async (openedMarkerID) => {
            const headers = {
              Authorization: `Bearer ${this.token}`
            }
            const sessionResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${openedMarkerID}/files`, { headers })
            let file = sessionResponse.data.files[0]

            if (file && file.stats && file.stats[0]) {
              if (file.type === 'jpeg' || file.type === 'jpg') {
                if (file.stats[0].address) {
                  this.infoWindows.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    address: file.stats[0].address,
                    session_id: openedMarkerID,
                    icon: this.owhiteMan
                  })
                } else {
                  this.markers.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    session_id: openedMarkerID,
                    icon: this.owhiteMan
                  })
                }

                const pointA = new this.google.maps.LatLng(parseFloat(file.stats[0].lat), parseFloat(file.stats[0].lon))
                const distance = 20 // in meters
                let bearing = parseFloat(file.stats[0].heading)
                let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                const lineSymbol = {
                  path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                }

                this.directionPaths.push({
                  path: [
                    {
                      lat: pointA.lat(),
                      lng: pointA.lng()
                    },
                    {
                      lat: pointB.lat(),
                      lng: pointB.lng()
                    }
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '100%',
                    },
                  ],
                  geodesic: true,
                  strokeColor: '#FA86C4',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })

                if ((file.stats[0].course && parseFloat(file.stats[0].course) > 0) && (file.stats[0].speed && parseFloat(file.stats[0].speed) > 0)) {
                  bearing = parseFloat(file.stats[0].course)
                  pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  this.directionPathCourses.push({
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#006721',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  })
                }
              } else if (file.type === 'obj') {
                if (file.stats[0].address) {
                  this.infoWindows.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    address: file.stats[0].address,
                    session_id: openedMarkerID,
                    icon: this.oblueMan
                  })
                } else {
                  this.markers.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    session_id: openedMarkerID,
                    icon: this.oblueMan
                  })
                }

                const pointA = new this.google.maps.LatLng(parseFloat(file.stats[0].lat), parseFloat(file.stats[0].lon))
                const distance = 20 // in meters
                let bearing = parseFloat(file.stats[0].heading)
                let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                const lineSymbol = {
                  path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                }

                this.directionPaths.push({
                  path: [
                    {
                      lat: pointA.lat(),
                      lng: pointA.lng()
                    },
                    {
                      lat: pointB.lat(),
                      lng: pointB.lng()
                    }
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '100%',
                    },
                  ],
                  geodesic: true,
                  strokeColor: '#FA86C4',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })

                if ((file.stats[0].course && parseFloat(file.stats[0].course) > 0) && (file.stats[0].speed && parseFloat(file.stats[0].speed) > 0)) {
                  bearing = parseFloat(file.stats[0].course)
                  pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  this.directionPathCourses.push({
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#006721',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  })
                }
              } else if (file.type === 'mov') {
                const fileResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${openedMarkerID}/files/${file.uid}`, { headers })
                file = fileResponse.data.file

                const flightPlanCoordinates = []
                const flightPlanCoordinatesTracker = []

                let data = {
                  uid: file.uid,
                  lat: file.stats[0].lat,
                  lon: file.stats[0].lon,
                  session_id: openedMarkerID,
                  icon: this.oredMan
                }

                await Promise.all(file.stats.map(async (stat) => {
                  if (stat.address) {
                    data = {
                      uid: file.uid,
                      lat: stat.lat,
                      lon: stat.lon,
                      address: stat.address,
                      session_id: openedMarkerID,
                      icon: this.oredMan
                    }
                  }
                }))

                if (data.address) {
                  this.infoWindows.push(data)
                } else {
                  this.markers.push(data)
                }

                const pointA = new this.google.maps.LatLng(parseFloat(file.stats[0].lat), parseFloat(file.stats[0].lon))
                const distance = 20 // in meters
                let bearing = parseFloat(file.stats[0].heading)
                let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                const lineSymbol = {
                  path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                }

                this.directionPaths.push({
                  path: [
                    {
                      lat: pointA.lat(),
                      lng: pointA.lng()
                    },
                    {
                      lat: pointB.lat(),
                      lng: pointB.lng()
                    }
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '100%',
                    },
                  ],
                  geodesic: true,
                  strokeColor: '#FA86C4',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })

                let max_speed = file.stats[0].speed ? parseFloat(file.stats[0].speed) : null
                if (max_speed) {
                  file.stats.forEach((stat) => {
                    if (max_speed < parseFloat(stat.speed)) {
                      max_speed = stat.speed
                    }
                  })
                }
                if ((file.stats[0].course && parseFloat(file.stats[0].course) > 0) && (max_speed && max_speed > 0)) {
                  bearing = parseFloat(file.stats[0].course)
                  pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  this.directionPathCourses.push({
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#006721',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  })
                }
              } else {
                const filesResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/sessions/${openedMarkerID}/files-ids?sort_by=timestamp`, {}, { headers })
                const files = filesResponse.data.files
                let stats = []

                files.forEach((file) => {
                  stats.push(Object.assign(file.first_location, { parent_id: file.uid }))
                  stats.push(Object.assign(file.last_location, { parent_id: file.uid }))
                })
                stats = stats.sort((a,b) => {
                  return new Date(a.timestamp) - new Date(b.timestamp)
                })

                if (stats[0].address) {
                  this.infoWindows.push({
                    uid: file.uid,
                    lat: stats[0].lat,
                    lon: stats[0].lon,
                    address: stats[0].address,
                    session_id: openedMarkerID,
                    icon: this.ogreenMan
                  })
                } else {
                  this.markers.push({
                    uid: file.uid,
                    lat: stats[0].lat,
                    lon: stats[0].lon,
                    session_id: openedMarkerID,
                    icon: this.ogreenMan
                  })
                }
                const pointA = new this.google.maps.LatLng(parseFloat(stats[0].lat), parseFloat(stats[0].lon))
                const distance = 20 // in meters
                let bearing = parseFloat(stats[0].heading)
                let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                const lineSymbol = {
                  path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                }

                this.directionPaths.push({
                  path: [
                    {
                      lat: pointA.lat(),
                      lng: pointA.lng()
                    },
                    {
                      lat: pointB.lat(),
                      lng: pointB.lng()
                    }
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '100%',
                    },
                  ],
                  geodesic: true,
                  strokeColor: '#FA86C4',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })

                bearing = parseFloat(stats[0].course)
                pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                this.directionPathCourses.push({
                  path: [
                    {
                      lat: pointA.lat(),
                      lng: pointA.lng()
                    },
                    {
                      lat: pointB.lat(),
                      lng: pointB.lng()
                    }
                  ],
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '100%',
                    },
                  ],
                  geodesic: true,
                  strokeColor: '#006721',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })
              }
            }
          }))

          this.loader.hide()
        } catch (error) {
          console.log(error)
          this.loader.hide()
          this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
        }
      }
    },
    showPath: async function () {
      if (this.openedMarkerIDs.length > 0) {
        this.loader = this.$loading.show()
        try {
          this.infoWindows = []
          this.markers = []
          this.liveOn = false
          await Promise.all(this.openedMarkerIDs.map(async (openedMarkerID) => {
            const headers = {
              Authorization: `Bearer ${this.token}`
            }
            const sessionResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${openedMarkerID}/files`, { headers })
            let file = sessionResponse.data.files[0]

            if (file && file.stats && file.stats[0]) {
              if (file.type === 'jpeg' || file.type === 'jpg') {
                if (file.stats[0].address) {
                  this.infoWindows.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    address: file.stats[0].address,
                    session_id: openedMarkerID,
                    icon: this.owhiteMan
                  })
                } else {
                  this.markers.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    session_id: openedMarkerID,
                    icon: this.owhiteMan
                  })
                }
              } else if (file.type === 'obj') {
                if (file.stats[0].address) {
                  this.infoWindows.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    address: file.stats[0].address,
                    session_id: openedMarkerID,
                    icon: this.oblueMan
                  })
                } else {
                  this.markers.push({
                    uid: file.uid,
                    lat: file.stats[0].lat,
                    lon: file.stats[0].lon,
                    session_id: openedMarkerID,
                    icon: this.oblueMan
                  })
                }
              } else if (file.type === 'mov') {
                const fileResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${openedMarkerID}/files/${file.uid}`, { headers })
                file = fileResponse.data.file

                const flightPlanCoordinates = []
                const flightPlanCoordinatesTracker = []

                let data = {
                  uid: file.uid,
                  lat: file.stats[0].lat,
                  lon: file.stats[0].lon,
                  session_id: openedMarkerID,
                  icon: this.oredMan
                }

                await Promise.all(file.stats.map(async (stat) => {
                  if (stat.address) {
                    data = {
                      uid: file.uid,
                      lat: stat.lat,
                      lon: stat.lon,
                      address: stat.address,
                      session_id: openedMarkerID,
                      icon: this.oredMan
                    }
                  }

                  const newCoordinate = {
                    lat: parseFloat(stat.lat),
                    lng: parseFloat(stat.lon)
                  }
                  const newCoordinateTracker = `${stat.lat},${stat.lon}`
                  if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
                    flightPlanCoordinates.push(newCoordinate)
                    flightPlanCoordinatesTracker.push(newCoordinateTracker)
                  }
                }))

                if (data.address) {
                  this.infoWindows.push(data)
                } else {
                  this.markers.push(data)
                }

                const lineSymbol = {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  scale: 4,
                }
                this.flightPaths.push({
                  path: flightPlanCoordinates,
                  geodesic: true,
                  strokeColor: '#00ff00',
                  strokeOpacity: 0,
                  strokeWeight: 2,
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '0',
                      repeat: '20px',
                    },
                  ],
                })

                this.lastPoints.push({
                  lat: parseFloat(file.stats[file.stats.length - 1].lat),
                  lng: parseFloat(file.stats[file.stats.length - 1].lon)
                })
              } else {
                const filesResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/sessions/${openedMarkerID}/files-ids?sort_by=timestamp`, {}, { headers })
                const files = filesResponse.data.files
                let stats = []

                files.forEach((file) => {
                  stats.push(Object.assign(file.first_location, { parent_id: file.uid }))
                  stats.push(Object.assign(file.last_location, { parent_id: file.uid }))
                })
                stats = stats.sort((a,b) => {
                  return new Date(a.timestamp) - new Date(b.timestamp)
                })

                if (stats[0].address) {
                  this.infoWindows.push({
                    uid: file.uid,
                    lat: stats[0].lat,
                    lon: stats[0].lon,
                    address: stats[0].address,
                    session_id: openedMarkerID,
                    icon: this.ogreenMan
                  })
                } else {
                  this.markers.push({
                    uid: file.uid,
                    lat: stats[0].lat,
                    lon: stats[0].lon,
                    session_id: openedMarkerID,
                    icon: this.ogreenMan
                  })
                }

                const flightPlanCoordinates = []
                const flightPlanCoordinatesTracker = []
                stats.forEach((stat) => {
                  const newCoordinate = {
                    lat: parseFloat(stat.lat),
                    lng: parseFloat(stat.lon)
                  }
                  const newCoordinateTracker = `${stat.lat},${stat.lon}`
                  if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
                    flightPlanCoordinates.push(newCoordinate)
                    flightPlanCoordinatesTracker.push(newCoordinateTracker)
                  }
                })

                const lineSymbol = {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  scale: 4,
                }
                this.flightPaths.push({
                  path: flightPlanCoordinates,
                  geodesic: true,
                  strokeColor: '#00ff00',
                  strokeOpacity: 0,
                  strokeWeight: 2,
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '0',
                      repeat: '20px',
                    },
                  ],
                })

                this.lastPoints.push({
                  lat: parseFloat(stats[stats.length - 1].lat),
                  lng: parseFloat(stats[stats.length - 1].lon)
                })
              }
            }
          }))

          this.loader.hide()
        } catch (error) {
          console.log(error)
          this.loader.hide()
          this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
        }
      }
    },
    selectedMarkerIDChange: async function (items) {
      if (items.currentSelectedItems.length > 10) {
        this.$vaToast.init('Selection of max 10 allowed.')
        this.selectedMarkerIDs = items.previousSelectedItems
      } else {
        this.openedMarkerIDs = []

        if (this.groupMode) {
        this.markers.forEach((marker) => {
          if (marker.type === 'Telemetry Mode') {
            marker.icon = this.greenMan
          } else if (marker.type === 'Video Mode') {
            marker.icon = this.redMan
          } else if (marker.type === 'Photo Mode') {
            marker.icon = this.whiteMan
          } else if (marker.type === 'LIDAR Mode') {
            marker.icon = this.blueMan
          }
        })
        this.infoWindows.forEach((infoWindow) => {
          if (infoWindow.type === 'Telemetry Mode') {
            infoWindow.icon = this.greenMan
          } else if (infoWindow.type === 'Video Mode') {
            infoWindow.icon = this.redMan
          } else if (infoWindow.type === 'Photo Mode') {
            infoWindow.icon = this.whiteMan
          } else if (infoWindow.type === 'LIDAR Mode') {
            infoWindow.icon = this.blueMan
          }
        })
        } else {
          this.markers.forEach((marker) => {
            if (marker.type === 'Telemetry Mode') {
              marker.icon = this.greenMan
            } else if (marker.type === 'Video Mode') {
              marker.icon = this.redMan
            } else if (marker.type === 'Photo Mode') {
              marker.icon = this.whiteMan
            } else if (marker.type === 'LIDAR Mode') {
              marker.icon = this.blueMan
            }
          })
          this.infoWindows.forEach((infoWindow) => {
            if (infoWindow.type === 'Telemetry Mode') {
              infoWindow.icon = this.greenMan
            } else if (infoWindow.type === 'Video Mode') {
              infoWindow.icon = this.redMan
            } else if (infoWindow.type === 'Photo Mode') {
              infoWindow.icon = this.whiteMan
            } else if (infoWindow.type === 'LIDAR Mode') {
              infoWindow.icon = this.blueMan
            }
          })
        }

        this.openedMarkerIDs = await Promise.all(items.currentSelectedItems.map((item) => {

          this.markers.forEach((marker) => {
            if (marker.uid === item.uid) {
              if (marker.type === 'Telemetry Mode') {
                marker.icon = this.greenMan
              } else if (marker.type === 'Video Mode') {
                marker.icon = this.redMan
              } else if (marker.type === 'Photo Mode') {
                marker.icon = this.whiteMan
              } else if (marker.type === 'LIDAR Mode') {
                marker.icon = this.blueMan
              }
            }
          })
          this.infoWindows.forEach((infoWindow) => {
            if (infoWindow.uid === item.uid) {
              if (infoWindow.type === 'Telemetry Mode') {
                infoWindow.icon = this.greenMan
              } else if (infoWindow.type === 'Video Mode') {
                infoWindow.icon = this.redMan
              } else if (infoWindow.type === 'Photo Mode') {
                infoWindow.icon = this.whiteMan
              } else if (infoWindow.type === 'LIDAR Mode') {
                infoWindow.icon = this.blueMan
              }
            }
          })
          return item.uid
        }))
      }
    },
    toggleFilters () {
      this.showFilters = !this.showFilters
    },
    zoomChanged (zoom) {
      this.zoom = zoom
      this.processViewChange(zoom, this.bounds)
    },
    boundChanged (bounds) {
      this.bounds = bounds
      this.processViewChange(this.zoom, bounds)
    },
    processViewChange (zoom, bounds) {
      const lat_found = bounds[Object.keys(bounds)[0]]
      const lon_found = bounds[Object.keys(bounds)[1]]
      let count = 0
      if (zoom >= 14) { // 500m
        if (this.processViewChangeTimeout) {
          clearTimeout(this.processViewChangeTimeout)
          this.processViewChangeTimeout = null
        }
        this.processViewChangeTimeout = setTimeout(() => {
          this.$refs.gMap.$mapPromise.then((map) => {
            this.markers.forEach(async (m) => {
              if (parseFloat(m.lat) <= lat_found.hi && parseFloat(m.lat) >= lat_found.lo && parseFloat(m.lon) <= lon_found.hi && parseFloat(m.lon) >= lon_found.lo && !this.visibleData[m.uid]) {
                try {
                  count = count + 1
                  this.visibleData[m.uid] = true
                  const headers = {
                    Authorization: `Bearer ${this.token}`
                  }
                  const sessionResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${m.uid}/files`, { headers })
                  let file = sessionResponse.data.files[0]

                  if (file && file.stats && file.stats[0]) {
                    this.showDetailsByID(m.uid, file)
                  }
                } catch (error) {
                  console.log(error)
                  this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
                }
              }
            })
            this.infoWindows.forEach(async (m) => {
              if (parseFloat(m.lat) <= lat_found.hi && parseFloat(m.lat) >= lat_found.lo && parseFloat(m.lon) <= lon_found.hi && parseFloat(m.lon) >= lon_found.lo && !this.visibleData[m.uid]) {
                try {
                  count = count + 1
                  this.visibleData[m.uid] = true
                  const headers = {
                    Authorization: `Bearer ${this.token}`
                  }
                  const sessionResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${m.uid}/files`, { headers })
                  let file = sessionResponse.data.files[0]

                  if (file && file.stats && file.stats[0]) {
                    this.showDetailsByID(m.uid, file)
                  }
                } catch (error) {
                  console.log(error)
                  this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
                }
              }
            })
          })
        }, 2000)
      } else if (zoom < 10) {
        if (this.processViewChangeTimeout) {
          clearTimeout(this.processViewChangeTimeout)
          this.processViewChangeTimeout = null
        }
        this.flightPaths = []
        this.directionPaths = []
        this.directionPathCourses = []
        this.lastPoints = []
        this.visibleData = {}
      } else {
        if (this.processViewChangeTimeout) {
          clearTimeout(this.processViewChangeTimeout)
          this.processViewChangeTimeout = null
        }
      }
    },
    showDetailsByID: async function (id, file) {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        if (file.type === 'jpeg' || file.type === 'jpg') {
          const pointA = new this.google.maps.LatLng(parseFloat(file.stats[0].lat), parseFloat(file.stats[0].lon))
          const distance = 20 // in meters
          let bearing = parseFloat(file.stats[0].heading)
          let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

          const lineSymbol = {
            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          }

          this.directionPaths.push({
            path: [
              {
                lat: pointA.lat(),
                lng: pointA.lng()
              },
              {
                lat: pointB.lat(),
                lng: pointB.lng()
              }
            ],
            icons: [
              {
                icon: lineSymbol,
                offset: '100%',
              },
            ],
            geodesic: true,
            strokeColor: '#FA86C4',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          })

          if ((file.stats[0].course && parseFloat(file.stats[0].course) > 0) && (file.stats[0].speed && parseFloat(file.stats[0].speed) > 0)) {
            bearing = parseFloat(file.stats[0].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourses.push({
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            })
          }
        } else if (file.type === 'obj') {
          const pointA = new this.google.maps.LatLng(parseFloat(file.stats[0].lat), parseFloat(file.stats[0].lon))
          const distance = 20 // in meters
          let bearing = parseFloat(file.stats[0].heading)
          let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

          const lineSymbol = {
            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          }

          this.directionPaths.push({
            path: [
              {
                lat: pointA.lat(),
                lng: pointA.lng()
              },
              {
                lat: pointB.lat(),
                lng: pointB.lng()
              }
            ],
            icons: [
              {
                icon: lineSymbol,
                offset: '100%',
              },
            ],
            geodesic: true,
            strokeColor: '#FA86C4',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          })

          if ((file.stats[0].course && parseFloat(file.stats[0].course) > 0) && (file.stats[0].speed && parseFloat(file.stats[0].speed) > 0)) {
            bearing = parseFloat(file.stats[0].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourses.push({
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            })
          }
        } else if (file.type === 'mov') {
          const fileResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files/${file.uid}`, { headers })
          file = fileResponse.data.file

          const flightPlanCoordinates = []
          const flightPlanCoordinatesTracker = []

          const pointA = new this.google.maps.LatLng(parseFloat(file.stats[0].lat), parseFloat(file.stats[0].lon))
          const distance = 20 // in meters
          let bearing = parseFloat(file.stats[0].heading)
          let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

          let lineSymbol = {
            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          }

          this.directionPaths.push({
            path: [
              {
                lat: pointA.lat(),
                lng: pointA.lng()
              },
              {
                lat: pointB.lat(),
                lng: pointB.lng()
              }
            ],
            icons: [
              {
                icon: lineSymbol,
                offset: '100%',
              },
            ],
            geodesic: true,
            strokeColor: '#FA86C4',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          })

          let max_speed = file.stats[0].speed ? parseFloat(file.stats[0].speed) : null
          if (max_speed) {
            file.stats.forEach((stat) => {
              if (max_speed < parseFloat(stat.speed)) {
                max_speed = stat.speed
              }
            })
          }
          if ((file.stats[0].course && parseFloat(file.stats[0].course) > 0) && (max_speed && max_speed > 0)) {
            bearing = parseFloat(file.stats[0].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourses.push({
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            })
          }

          await Promise.all(file.stats.map(async (stat) => {
            const newCoordinate = {
              lat: parseFloat(stat.lat),
              lng: parseFloat(stat.lon)
            }
            const newCoordinateTracker = `${stat.lat},${stat.lon}`
            if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
              flightPlanCoordinates.push(newCoordinate)
              flightPlanCoordinatesTracker.push(newCoordinateTracker)
            }
          }))

          lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 4,
          }
          this.flightPaths.push({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: '#00ff00',
            strokeOpacity: 0,
            strokeWeight: 2,
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '20px',
              },
            ],
          })
          this.lastPoints.push({
            lat: parseFloat(file.stats[file.stats.length - 1].lat),
            lng: parseFloat(file.stats[file.stats.length - 1].lon)
          })
        } else {
          const filesResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/sessions/${id}/files-ids?sort_by=timestamp`, {}, { headers })
          const files = filesResponse.data.files
          let stats = []

          files.forEach((file) => {
            stats.push(Object.assign(file.first_location, { parent_id: file.uid }))
            stats.push(Object.assign(file.last_location, { parent_id: file.uid }))
          })
          stats = stats.sort((a,b) => {
            return new Date(a.timestamp) - new Date(b.timestamp)
          })

          const pointA = new this.google.maps.LatLng(parseFloat(stats[0].lat), parseFloat(stats[0].lon))
          const distance = 20 // in meters
          let bearing = parseFloat(stats[0].heading)
          let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

          let lineSymbol = {
            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          }

          this.directionPaths.push({
            path: [
              {
                lat: pointA.lat(),
                lng: pointA.lng()
              },
              {
                lat: pointB.lat(),
                lng: pointB.lng()
              }
            ],
            icons: [
              {
                icon: lineSymbol,
                offset: '100%',
              },
            ],
            geodesic: true,
            strokeColor: '#FA86C4',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          })

          bearing = parseFloat(stats[0].course)
          pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

          this.directionPathCourses.push({
            path: [
              {
                lat: pointA.lat(),
                lng: pointA.lng()
              },
              {
                lat: pointB.lat(),
                lng: pointB.lng()
              }
            ],
            icons: [
              {
                icon: lineSymbol,
                offset: '100%',
              },
            ],
            geodesic: true,
            strokeColor: '#006721',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          })

          const flightPlanCoordinates = []
          const flightPlanCoordinatesTracker = []
          stats.forEach((stat) => {
            const newCoordinate = {
              lat: parseFloat(stat.lat),
              lng: parseFloat(stat.lon)
            }
            const newCoordinateTracker = `${stat.lat},${stat.lon}`
            if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
              flightPlanCoordinates.push(newCoordinate)
              flightPlanCoordinatesTracker.push(newCoordinateTracker)
            }
          })

          lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 4,
          }
          this.flightPaths.push({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: '#00ff00',
            strokeOpacity: 0,
            strokeWeight: 2,
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '20px',
              },
            ],
          })
          this.lastPoints.push({
            lat: parseFloat(stats[stats.length - 1].lat),
            lng: parseFloat(stats[stats.length - 1].lon)
          })
        }
      } catch (error) {
        console.log(error)
        this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
      }
    }
  },
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
      &__title {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .grow {
    transition: all .2s ease-in-out;
  }
  .grow:hover {
    transform: scale(1.05);
  }
  .detailLink {
    text-align: center;
  }
  .detailLink:hover {
    text-decoration: underline;
  }
  .detailFile:hover {
    text-decoration: underline;
  }

  .live-text {
    color: var(--va-dark) !important;
  }

  .link {
    cursor: pointer;
    color: var(--va-primary);
  }

  .link:hover {
    text-decoration: none;
  }

  .legend-title {
    width: 100%;
    text-align: center;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--va-dark);
    text-decoration: underline;
  }

  .legend-title-mobile {
    width: 100%;
    text-align: center;
    padding: 0;
    font-size: .75rem;
    font-weight: bold;
    color: var(--va-dark);
    text-decoration: underline;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .gm-fullscreen-control {
    background-color: var(--va-white) !important;
  }

  .gmnoprint > .gmnoprint > div {
    background-color: var(--va-white) !important;
  }

  .gm-style-mtc > button {
    background-color: var(--va-white) !important;
    color: var(--va-dark) !important;
  }

  .va-date-picker {
    --va-date-picker-text-color: var(--va-pure-dark);
  }

  .theme-colors-one {
    color: var(--va-white);
    background-color: var(--va-white);
  }

  .theme-colors-two {
    color: var(--va-dark);
    background-color: var(--va-white);
  }

  #labelsCheck {
    accent-color: var(--va-primary);
  }
</style>
